import React from "react";
import { useState } from "react";
import styles from "./index.module.css";
const ShowMore = (props) => {
  const [showMore, setShowMore] = useState(false);
  const smoothScroll = () => {
    let element = document.getElementById(`${props.iden}`);
    setShowMore(!showMore);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className={styles["show-more"]} id={`${props.iden}`}>
      {showMore ? (
        <div className={styles["children"]}>{props.children}</div>
      ) : null}
      <div className={styles["children-desktop"]}>{props.children}</div>

      <button
        className={styles["show-more-btn"]}
        onClick={() => smoothScroll()}
      >
        {showMore ? "Daha az" : "Daha ətraflı"}
      </button>
    </div>
  );
};
export default ShowMore;
