import styles from "./index.module.css";
const Title = (props) => {
  return (
    <div>
      <h2 className={styles["title"]}>{props.children}</h2>
      <div className={styles["red-line"]}></div>
    </div>
  );
};
export default Title;
