import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routesArr } from "./routes";

import TheHeader from "./components/layouts/theHeader";
import TheFooter from "./components/layouts/theFooter";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routesArr.map((item) => (
          <Route
            exact
            path={item.path}
            key={item.id}
            element={
              <>
                <TheHeader />
                <item.component />
                <TheFooter />
              </>
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
