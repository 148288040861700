import { NavLink } from "react-router-dom";
import { FacebookIcon, InstagramIcon, LinkedinIcon } from "../../../icons";
import Logo from "../../../assets/images/pro_bill_logo.svg";
import styles from "./index.module.css";
import Wrapper from "../../UI/wrapper";
function TheFooter() {
  return (
    <footer className={styles["footer"]} id="contact">
      <Wrapper>
        <div className={styles["footer-upper"]}>
          <div className={styles["w-40"]}>
            <NavLink to="/">
              <img src={Logo} alt="Probill" className={styles["logo"]} />
            </NavLink>
            <p className={styles["footer-p"]}>
              Müvafiq biznes sferalarında təkrarlanan vaxt intervalları üzrə
              müəyyən olunmuş tariflərə uyğun abonentlərin borclandırılması və
              hesabat proseslərinin avtomatlaşdırılması sistemi
            </p>
            <div className={styles["socials"]}>
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <FacebookIcon />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noreferrer">
                <InstagramIcon />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                <LinkedinIcon />
              </a>
            </div>
          </div>
          <div className={styles["w-60"]}>
            <div className={styles["w-20"]}>
              <h4>Elektron ünvanı</h4>
              <ul>
                <li>
                  <a href="mailto:info@probill.az">info@probill.az</a>
                </li>
                <li>
                  <a href="mailto:sales@probill.az">sales@probill.az</a>
                </li>
              </ul>
            </div>
            <div className={styles["w-20"]}>
              <h4>Əlaqə nömrəsi</h4>
              <ul>
                <li>
                  <a href="tel:+994125259515">+994 12 525 95 15</a>
                </li>
                <li>
                  <a href="tel:+994703229515">+994 70 322 95 15</a>
                </li>
              </ul>
            </div>
            <div className={styles["w-20"]}>
              <h4>Ünvan</h4>
              <ul>
                <li>
                  <NavLink>
                    Bakı şəhər, Əhməd Rəcəbli 3cü paralel küçəsi
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <hr />
      <Wrapper>
        <div className={styles["footer-lower"]}>
          <p>© 2022 Probill . All Rights Reserved</p>
        </div>
      </Wrapper>
    </footer>
  );
}
export default TheFooter;
