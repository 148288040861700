import Card from "../UI/card";
import Wrapper from "../UI/wrapper";
import {
  EducationIcon,
  ApartamentComplexIcon,
  BusinessCenterIcon,
  // ObjectIcon,
  ParkingServiceIcon,
  LiftServiceIcon,
  SmartHomeIcon,
  ProductsIcon,
} from "../../icons";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import Title from "../UI/title";
import SubTitle from "../UI/subTitle";
import RedBlur from "../../assets/images/decorations/red-blur.svg";
import Waves from "../../assets/images/decorations/waves.svg";
import Plusses from "../../assets/images/decorations/plusses.svg";
import ShowMore from "../UI/showMore";
const Products = () => {
  return (
    <Wrapper>
      <div id="products"></div>
      <div className={styles["blue-blur"]}>
        <img src={RedBlur} alt="" className={styles["blue-blur-img"]} />
      </div>
      <div className={styles["waves"]}>
        <img src={Waves} alt="" className={styles["waves-img"]} />
      </div>
      <div className={styles["plusses"]}>
        <img src={Plusses} alt="" className={styles["plusses-img"]} />
      </div>
      <div className={styles["icon-container"]}>
        <div className={styles["icon"]}>
          <ProductsIcon />
        </div>
      </div>
      <Title>Məhsullar</Title>
      <SubTitle>
        ProBill-in fərqli biznes sferaları üçün hazırlanmış məhsulları ilə tanış
        ola bilərsiniz
      </SubTitle>
      <ShowMore iden={"prods"}>
        <div className={styles["card-grid"]}>
          <Card>
            <div className={styles["card-container"]}>
              <EducationIcon />
              <h3>Təhsil Billinqi</h3>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <ApartamentComplexIcon />
              <h3>Yaşayış kompleksi idarə etmə sistemi</h3>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <BusinessCenterIcon />
              <h3>Plaza, Mall və Biznes mərkəzlərin idarəetmə sistemi</h3>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          {/* <Card>
          <div className={styles["card-container"]}>
          <ObjectIcon />
          <h3>Qeyri-yaşayış obyektləri</h3>
          <Link>Daha ətraflı &rarr;</Link>
          </div>
        </Card> */}
          <Card>
            <div className={styles["card-container"]}>
              <ParkingServiceIcon />
              <h3>Parking servis</h3>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <LiftServiceIcon />
              <h3>Lift servis</h3>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <SmartHomeIcon />
              <h3>Damafon və ağıllı ev sistemləri servisi</h3>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          {/* <Card>
          <div className={styles["card-container"]}>
            <EducationIcon />
            <h3>
            Təhsil <br /> Billinqi
            </h3>
            <Link>Daha ətraflı &rarr;</Link>
            </div>
        </Card> */}
        </div>
      </ShowMore>
    </Wrapper>
  );
};
export default Products;
