import SubTitle from "../UI/subTitle";
import Title from "../UI/title";
import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import BlueBlur from "../../assets/images/decorations/blue-blur.svg";
import Plusses from "../../assets/images/decorations/plusses.svg";
import { UserIconWhite } from "../../icons";
import ShowMore from "../UI/showMore";

const AdministrativeSolutions = () => {
  return (
    <Wrapper>
      <div className={styles["blue-blur"]}>
        <img src={BlueBlur} alt="" className={styles["blue-blur-img"]} />
      </div>
      <div className={styles["plusses"]}>
        <img src={Plusses} alt="" className={styles["plusses-img"]} />
      </div>
      <div className={styles["icon-container"]}>
        <div className={styles["icon"]}>
          <UserIconWhite />
        </div>
      </div>
      <Title>İdarəçilər üçün həllər</Title>
      <SubTitle>
        ProBill, iş proseslərini avtomatlaşdıraraq idarəçiləri zaman, maliyyə və
        məlumat itgisindən azad edir
      </SubTitle>
      <ShowMore iden={"adSolution"}>
        <div className={styles["card-grid"]}>
          <div className={styles["card-container"]}>
            <div className={styles["blob-lightgreen"]}></div>
            <h3>Online və offline</h3>
            <p>Ödənişlərin onayn, terminal və nağd şəkildə qəbulu</p>
          </div>
          <div className={styles["card-container"]}>
            <div className={styles["blob-orange"]}></div>
            <h3>İnteqrasiya imkanı</h3>
            <p>Onlayn ödəmə və avtomatlaşdırılmış sistemlərə inteqrasiya</p>
          </div>
          <div className={styles["card-container"]}>
            <div className={styles["blob-red"]}></div>
            <h3>Rəqəmsal Asistant </h3>
            <p>
              Hər an(yolda, istirahətdə, xaricdə) mobil və kompyuter vasitəsilə
              sistemi idarə və nəzarət etmək imkanı
            </p>
          </div>
          <div className={styles["card-container"]}>
            <div className={styles["blob-blue"]}></div>
            <h3>Smart sistem</h3>
            <p>
              Avtomatlaşdırılmış avadanlıq və onlayn ödəmə sistemlərinə
              inteqrasiya olunaraq smart(manual müdaxiləsiz) sistemmə nail olmaq
            </p>
          </div>
          <div className={styles["card-container"]}>
            <div className={styles["blob-purple"]}></div>
            <h3>Daşbord</h3>
            <p>
              Real vaxt rejimində dəyişən hesabatların diaqramlarla qısa və
              konkretləşdirilmiş görünüşü
            </p>
          </div>
          <div className={styles["card-container"]}>
            <div className={styles["blob-green"]}></div>
            <h3>CRM</h3>
            <p>
              Ödəyicilərə(sakin/icarəçi/tələb) dair vacib məlumatların
              saxlanılması və istifadəsi imkanı
            </p>
          </div>
          <div className={styles["card-container"]}>
            <div className={styles["blob-yellow"]}></div>
            <h3>Borcları görmə</h3>
            <p>Real vaxt rejimində ödəniş, borc və avansların təqib edilməsi</p>
          </div>
          <div className={styles["card-container"]}>
            <div className={styles["blob-lightgreen"]}></div>
            <h3>SMS bildirişlər</h3>
            <p>
              Məlumat bazasında olan ödəyicilərə istənilən kontent tərtib
              olunmuş toplu sms-lərin göndərilməsi
            </p>
          </div>
        </div>
      </ShowMore>
    </Wrapper>
  );
};
export default AdministrativeSolutions;
