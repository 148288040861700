import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import { logos } from "./logos.js";
import Marquee from "react-fast-marquee";
import Title from "../UI/title";

const Customers = () => {
  const slidesHandler = () => {
    if (window.innerWidth > 1000) return 40;
    else if (window.innerWidth < 1000) return 20;
    else return 10;
  };
  return (
    <Wrapper>
      <div id="customers" className={styles["titl"]}>
        <Title>Müştərilərimiz</Title>

        <Marquee
          gradient={false}
          pauseOnHover={true}
          speed={40}
          className={styles["marquee"]}
        >
          {logos.map((item, i) => (
            <div
              className={styles["customer-logo"]}
              key={i}
              style={{ margin: slidesHandler() + "px" }}
            >
              <img src={item} alt="customer" />
            </div>
          ))}
        </Marquee>
      </div>
    </Wrapper>
  );
};
export default Customers;
