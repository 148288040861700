import Card from "../UI/card";
import SubTitle from "../UI/subTitle";
import Title from "../UI/title";
import {
  BuildingIcon,
  BriefcaseIcon,
  BookIcon,
  OfficeIcon,
  AutomaticationIcon,
  MoneyCaseIcon,
  UserIconWhite,
} from "../../icons";
import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import ShowMore from "../UI/showMore";

const ForWho = () => {
  return (
    <Wrapper>
      <div id="forWho"></div>
      <div className={styles["icon-container"]}>
        <div className={styles["icon"]}>
          <UserIconWhite />
        </div>
      </div>
      <Title>Kimlər üçündür?</Title>
      <SubTitle>
        ProBill məhsullarının yaratdığı imkanlardan aşağıdaki istifadəçilər
        yararlana bilər
      </SubTitle>
      <ShowMore iden={"fWho"}>
        <div className={styles["card-grid"]}>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-blue"]}>
                <BuildingIcon />
              </span>
              <h3>Tikinti şirkətləri</h3>
              <p>
                Tikinti şirkətinin idarə etdiyi yaşayış və qeyri-yaşayış
                komplekslərinin hesabat və monitorinqini real vaxt rejimində
                vahid pəncərədən apara bilər
              </p>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-red"]}>
                <BriefcaseIcon />
              </span>
              <h3>Daşınmaz əmlak şirkətləri</h3>
              <p>
                Daşınmaz əmlak şirkətlərinin idarə etdiyi, icarəyə veridiyi və
                xidmət göstərdiyi daşınmaz əmlakları vahid sistemlə idarə edə
                bilər.
              </p>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-yellow"]}>
                <BookIcon />
              </span>
              <h3>Təhsil qurumları</h3>
              <p>
                Təhsil qurumları olan universitet, lisey və kurslar aylıq/illik
                təhsil haqqlarının yığılması, dərs cədvəllərinin tərtib olunması
                və təlimçilərin keçəcəyi dərslərlə əlaqələndirilməsi
                proseslərinin avtomatlaşdırılması və vahid pəncərədən idarə
                oluması
              </p>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-brown"]}>
                <OfficeIcon />
              </span>
              <h3>Mənzil MMC qurumları</h3>
              <p>
                Mənzil Mülkiyyətçilərinin Müştərək Cəmiyyəti qurumlarının şəffaf
                şəkildə idarə olunması ilə ədalət prinsipinin qorunmasını təmin
                edir
              </p>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-green"]}>
                <AutomaticationIcon />
              </span>
              <h3>Avtomatlaşdırma qurumları</h3>
              <p>
                Avtomatlaşdırma şirkətləri tərəfindən müxtəlif komplekslərdə
                quraşdırılmış damafon(lift, parkinq və.s acces ) sistemlərinə
                göstərilən servislərin, xidmət haqlarının yığılmasının və
                proseslərin avtomatlaşdırılması və vahid pəncərədən idarə
                olunması
              </p>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-purple"]}>
                <MoneyCaseIcon />
              </span>
              <h3>Ödəyicilər (sakin, tələbə, icarəçi)</h3>
              <p>
                Ödəyicilərin (sakin, tələbə, icarəçi) ödəyəcəkləri məbləği və
                cari borclarını onlayn ödəmə sistemlərində baxmaq, onlayn və
                terminallar vasitəsilə ödəyərək vaxt itgisini minimumlaşdırmaq
                və fraud-ların qarşısını almaq
              </p>
              <Link>Daha ətraflı &rarr;</Link>
            </div>
          </Card>
        </div>
      </ShowMore>
    </Wrapper>
  );
};
export default ForWho;
