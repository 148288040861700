import styles from "./index.module.css";
const IframePopup = ({ videoCode, setPlayVideo }) => {
  return (
    <div className={styles["overlay"]} onClick={() => setPlayVideo(false)}>
      <iframe
        // width="1280"
        // height="720"
        src={`https://www.youtube.com/embed/${videoCode}?autoplay=1`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};
export default IframePopup;
