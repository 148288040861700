// import styles from "./index.module.css";
import Jumbotron from "../../components/jumbotron";
import Customers from "../../components/customers";
import Tutorial from "../../components/tutorial";
import Products from "../../components/products";
import ForWho from "../../components/forWho";
import ResidentSolutions from "../../components/residentSolutions";
import AdministrativeSolutions from "../../components/administrativeSolutions";
import Partners from "../../components/partners";
import OnlineApplication from "../../components/onlineApplication";
function Home() {
  return (
    <>
      <Jumbotron />
      <Customers />
      <Tutorial />
      <Products />
      <ForWho />
      <ResidentSolutions />
      <AdministrativeSolutions />
      <Partners />
      <OnlineApplication />
    </>
  );
}
export default Home;
