const SearchEye = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1376_3695)">
        <path
          d="M11.749 6.24953C11.749 9.28683 9.28682 11.7491 6.24952 11.7491C3.21222 11.7491 0.75 9.28683 0.75 6.24953C0.75 3.21222 3.21222 0.75 6.24952 0.75C9.28682 0.75 11.749 3.21222 11.749 6.24953Z"
          stroke="#4392F1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M10.6765 10.6765L13.3364 13.1252"
          stroke="#4392F1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1376_3695">
          <rect width="13.3364" height="13.1252" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const DropDown = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.21431 6.33328C5.0901 6.33399 4.96697 6.31019 4.85198 6.26322C4.73699 6.21625 4.6324 6.14705 4.54421 6.05958L0.769018 2.28438C0.68102 2.19638 0.611216 2.09192 0.563592 1.97694C0.515967 1.86196 0.491455 1.73873 0.491455 1.61429C0.491455 1.48984 0.515967 1.36661 0.563592 1.25163C0.611216 1.13666 0.68102 1.03219 0.769018 0.944189C0.857017 0.85619 0.961486 0.786386 1.07646 0.738762C1.19144 0.691137 1.31467 0.666626 1.43912 0.666626C1.56356 0.666626 1.68679 0.691137 1.80177 0.738762C1.91674 0.786386 2.02121 0.85619 2.10921 0.944189L5.21431 4.06816L8.32884 1.06688C8.41567 0.970342 8.52147 0.892753 8.63965 0.83897C8.75782 0.785186 8.88582 0.756365 9.01565 0.754309C9.14547 0.752252 9.27432 0.777003 9.39414 0.827016C9.51396 0.877029 9.62217 0.951227 9.71201 1.04497C9.80185 1.13871 9.87137 1.24998 9.91625 1.37182C9.96112 1.49366 9.98037 1.62345 9.97279 1.75306C9.96521 1.88268 9.93097 2.00934 9.87221 2.12512C9.81345 2.2409 9.73143 2.34331 9.63128 2.42595L5.85609 6.06901C5.68349 6.23542 5.45404 6.3299 5.21431 6.33328Z"
        fill="#222222"
      />
    </svg>
  );
};
const FacebookIcon = () => {
  return (
    <svg
      width="7"
      height="15"
      viewBox="0 0 7 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60491 7.23951H4.66725V14.1743H1.77774V8.3741V7.23951H0.417969V4.79193H1.77774V3.1942C1.77774 2.07239 2.32165 0.304688 4.66725 0.304688H6.80888V2.68428H5.27914C5.04118 2.68428 4.66725 2.82026 4.66725 3.36417V4.79193H6.84288L6.60491 7.23951Z"
        fill="#081420"
      />
    </svg>
  );
};
const InstagramIcon = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74464 0.304688C8.66733 0.337642 9.62297 0.337642 10.5457 0.370592C11.3695 0.403544 12.1274 0.535357 12.8524 0.963746C13.7751 1.52395 14.3353 2.34777 14.533 3.40226C14.6319 4.02837 14.6648 4.68743 14.6978 5.31354C14.7307 6.69756 14.6978 8.08159 14.6978 9.46561C14.6978 10.1906 14.6648 10.9155 14.4341 11.6076C13.9728 12.9586 13.0172 13.7824 11.6331 14.0461C11.007 14.1779 10.3479 14.1779 9.72184 14.2108C8.3378 14.2438 6.98672 14.2108 5.60268 14.2108C4.87771 14.2108 4.15274 14.1779 3.46073 13.9472C2.10964 13.4859 1.28581 12.5302 1.02218 11.1462C0.890372 10.5201 0.890372 9.86104 0.85742 9.23494C0.824465 7.85091 0.85742 6.46689 0.85742 5.08287C0.85742 4.3579 0.890372 3.63294 1.12104 2.94092C1.58239 1.58985 2.53803 0.766031 3.92207 0.502405C4.54818 0.370597 5.20724 0.370597 5.83336 0.337642C6.45947 0.304688 7.08558 0.304688 7.74464 0.304688ZM13.4453 7.12595C13.4453 7.12595 13.4123 7.12595 13.4453 7.12595C13.4123 6.56575 13.4123 6.0385 13.4123 5.4783C13.4123 4.95105 13.3794 4.4238 13.3135 3.89656C13.1817 2.71025 12.4896 1.91938 11.3363 1.68871C10.7431 1.5569 10.084 1.5569 9.49089 1.5569C8.30457 1.52394 7.15121 1.52394 5.96489 1.5569C5.37174 1.5569 4.77858 1.58985 4.21837 1.68871C3.22977 1.85347 2.53776 2.41367 2.24118 3.40226C2.14232 3.73179 2.10936 4.06132 2.07641 4.39085C2.04346 5.64306 2.04346 6.89527 2.04346 8.14749C2.04346 8.93836 2.07641 9.76218 2.14232 10.5531C2.24118 11.7394 2.96615 12.5632 4.15247 12.7609C4.74562 12.8598 5.37174 12.8927 5.99785 12.8927C7.15121 12.9257 8.30457 12.8927 9.49089 12.8927C10.0181 12.8927 10.5454 12.8598 11.0726 12.7939C11.5669 12.7609 12.0283 12.5961 12.4237 12.2666C13.0828 11.7394 13.3135 11.0473 13.3464 10.2565C13.4123 9.26789 13.4123 8.21339 13.4453 7.12595Z"
        fill="#081420"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3035 7.25811C11.3035 9.23528 9.72169 10.817 7.7445 10.817C5.7673 10.817 4.18555 9.23528 4.18555 7.22517C4.18555 5.28095 5.80025 3.69922 7.77745 3.69922C9.72169 3.69922 11.3035 5.28095 11.3035 7.25811ZM7.74425 9.56478C8.99647 9.56478 10.051 8.5103 10.051 7.2581C10.051 6.00589 8.99647 4.9514 7.74425 4.9514C6.45908 4.9514 5.43752 6.00589 5.43752 7.2581C5.40457 8.5103 6.45908 9.56478 7.74425 9.56478Z"
        fill="#081420"
      />
      <g filter="url(#filter0_d_1406_1441)">
        <path
          d="M12.2587 3.53379C12.2587 3.99514 11.8962 4.39058 11.4349 4.39058C10.9735 4.39058 10.5781 3.99514 10.6111 3.53379C10.6111 3.07245 10.9735 2.70996 11.4349 2.70996C11.8962 2.70996 12.2587 3.07244 12.2587 3.53379Z"
          fill="#081420"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1406_1441"
          x="6.10913"
          y="0.459961"
          width="10.6497"
          height="10.6807"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.25" />
          <feGaussianBlur stdDeviation="2.25" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.160784 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1406_1441"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1406_1441"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
const LinkedinIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5823 8.41705V13.5387H11.5915V8.75351C11.5915 7.55722 11.1803 6.73476 10.0961 6.73476C9.27367 6.73476 8.78766 7.29553 8.56336 7.81891C8.48859 8.00583 8.45121 8.26752 8.45121 8.52921V13.5387H5.46045C5.46045 13.5387 5.49783 5.42632 5.46045 4.60387H8.45121V5.87493C8.45121 5.87493 8.45121 5.91231 8.41382 5.91231H8.45121V5.87493C8.86243 5.27679 9.53536 4.37956 11.1429 4.37956C13.0869 4.37956 14.5823 5.68801 14.5823 8.41705ZM2.39471 0.304688C1.38532 0.304688 0.712402 0.977603 0.712402 1.83744C0.712402 2.69727 1.34794 3.37019 2.35732 3.37019H2.39471C3.44147 3.37019 4.07701 2.69728 4.07701 1.83744C4.03962 0.977603 3.40409 0.304688 2.39471 0.304688ZM0.861938 13.5387H3.81532V4.60387H0.861938V13.5387Z"
        fill="#081420"
      />
    </svg>
  );
};
const RightArrow = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49992 7.83317C3.22378 7.83317 2.99992 8.05703 2.99992 8.33317C2.99992 8.5863 3.18802 8.7955 3.43207 8.82861L3.49992 8.83317H12.8333C13.1094 8.83317 13.3333 8.60931 13.3333 8.33317C13.3333 8.08004 13.1451 7.87084 12.9011 7.83773L12.8333 7.83317H3.49992Z"
        fill="#CBCBCB"
      />
      <path
        d="M13.1868 7.97962C13.0093 7.80211 12.7315 7.78597 12.5358 7.93121L12.4797 7.97962L9.81303 10.6463C9.61777 10.8415 9.61777 11.1581 9.81303 11.3534C9.99054 11.5309 10.2683 11.547 10.4641 11.4018L10.5201 11.3534L13.1868 8.68672C13.3821 8.49146 13.3821 8.17488 13.1868 7.97962Z"
        fill="#CBCBCB"
      />
      <path
        d="M10.5201 5.31295C10.3249 5.11769 10.0083 5.11769 9.81303 5.31295C9.63552 5.49046 9.61938 5.76824 9.76462 5.96398L9.81303 6.02006L12.4797 8.68672C12.675 8.88199 12.9915 8.88199 13.1868 8.68672C13.3643 8.50921 13.3805 8.23144 13.2352 8.0357L13.1868 7.97962L10.5201 5.31295Z"
        fill="#CBCBCB"
      />
    </svg>
  );
};
const PlayButton = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#D9D9D9" fillOpacity="0.42" />
      <path d="M26 43.5455V21L43 32L26 43.5455Z" fill="#D9D9D9" />
    </svg>
  );
};
const EducationIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_3336)">
        <rect opacity="0.15" width="44" height="44" rx="8.8" fill="#1DE2CF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 12.382L20.5587 12.1613C19.0313 11.3976 17.347 11 15.6393 11H14C13.4477 11 13 11.4477 13 12V26C13 26.5523 13.4477 27 14 27H15.6393C17.0365 27 18.4145 27.3253 19.6642 27.9502L21 28.618V12.382Z"
          fill="#1DE2CF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 28.618L24.3358 27.9502C25.5855 27.3253 26.9635 27 28.3607 27H30C30.5523 27 31 26.5523 31 26V12C31 11.4477 30.5523 11 30 11H28.3607C26.653 11 24.9687 11.3976 23.4413 12.1613L23 12.382V28.618Z"
          fill="#1DE2CF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6393 30H14V28H15.6393C17.347 28 19.0313 28.3976 20.5587 29.1613L22 29.882L23.4413 29.1613C24.9687 28.3976 26.653 28 28.3607 28H30V30H28.3607C26.9635 30 25.5855 30.3253 24.3358 30.9502L22.4472 31.8944C22.1657 32.0352 21.8343 32.0352 21.5528 31.8944L19.6642 30.9502C18.4145 30.3253 17.0365 30 15.6393 30Z"
          fill="#1DE2CF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_3336">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const ApartamentComplexIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_3365)">
        <rect opacity="0.15" width="44" height="44" rx="8.8" fill="#6001D3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.0712 33.0491H12.5156V31.0935H32.0712V33.0491Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4934 13.4934C13.4934 12.9534 13.9312 12.5156 14.4712 12.5156H30.1156C30.6556 12.5156 31.0934 12.9534 31.0934 13.4934V28.1601C31.0934 28.7001 30.6556 29.1378 30.1156 29.1378H14.4712C13.9312 29.1378 13.4934 28.7001 13.4934 28.1601V13.4934ZM15.449 14.4712V27.1823H29.1379V14.4712H15.449Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.1154 17.4045H14.4709V15.449H30.1154V17.4045Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.1154 21.8044H14.4709V19.8489H30.1154V21.8044Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.1154 26.2046H14.4709V24.249H30.1154V26.2046Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8711 13.0045V28.6489H16.9155V13.0045H18.8711Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2712 13.0045V28.6489H21.3157V13.0045H23.2712Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6711 13.0045V28.6489H25.7156V13.0045H27.6711Z"
          fill="#6001D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4712 27.1824C13.9312 27.1824 13.4934 27.6201 13.4934 28.1602V32.0713C13.4934 32.6113 13.9312 33.049 14.4712 33.049H30.1156C30.6556 33.049 31.0934 32.6113 31.0934 32.0713V28.1602C31.0934 27.6201 30.6556 27.1824 30.1156 27.1824H14.4712ZM24.249 32.0713V28.1602H26.2045V32.0713H24.249ZM18.8712 28.1602V32.0713H20.8267V28.1602H18.8712Z"
          fill="#6001D3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_3365">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const BusinessCenterIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_3394)">
        <rect opacity="0.15" width="44" height="44" rx="8.8" fill="#FF895B" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 15C18 14.4477 18.4477 14 19 14H25C25.5523 14 26 14.4477 26 15V32C26 32.5523 25.5523 33 25 33H19C18.4477 33 18 32.5523 18 32V15ZM20 16V31H24V16H20Z"
          fill="#FF895B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4576 16.6108C19.1245 16.4394 18.7236 16.4685 18.4188 16.6863L14.9188 19.1863C14.656 19.374 14.5 19.6771 14.5 20V32C14.5 32.5523 14.9477 33 15.5 33H19C19.5523 33 20 32.5523 20 32V17.5C20 17.1254 19.7907 16.7822 19.4576 16.6108ZM25.5812 16.6863C25.2764 16.4685 24.8755 16.4394 24.5424 16.6108C24.2093 16.7822 24 17.1254 24 17.5V32C24 32.5523 24.4477 33 25 33H28.5C29.0523 33 29.5 32.5523 29.5 32V20C29.5 19.6771 29.344 19.374 29.0812 19.1863L25.5812 16.6863Z"
          fill="#FF895B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 31H31V33H13V31Z"
          fill="#FF895B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 12V15.5H19V12H21Z"
          fill="#FF895B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 13V15.5H22V13H24Z"
          fill="#FF895B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 15V32H21V15H23Z"
          fill="#FF895B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_3394">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const ObjectIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_3422)">
        <rect opacity="0.15" width="44" height="44" rx="8.8" fill="#FCDC00" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7236 11.2776C22.0297 11.1273 22.3888 11.13 22.6927 11.2848L33.325 16.7015L32.3415 18.632L31.7499 18.3307V31.2084C31.7499 31.8067 31.2649 32.2917 30.6666 32.2917H13.3333C12.7349 32.2917 12.2499 31.8067 12.2499 31.2084V18.3418L11.644 18.6392L10.6892 16.6943L21.7236 11.2776ZM14.4166 17.2782V30.1251H29.5833V17.2268L22.1919 13.4613L14.4166 17.2782Z"
          fill="#FCDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0415 19.2917H20.3748V17.1251H16.0415V19.2917Z"
          fill="#FCDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.625 19.2917H27.9583V17.1251H23.625V19.2917Z"
          fill="#FCDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0416 32.2917C15.4433 32.2917 14.9583 31.8066 14.9583 31.2083V21.4583C14.9583 20.86 15.4433 20.375 16.0416 20.375H27.9583C28.5566 20.375 29.0416 20.86 29.0416 21.4583V31.2083C29.0416 31.8066 28.5566 32.2917 27.9583 32.2917H16.0416ZM18.7499 25.7917H25.2499V23.625H18.7499V25.7917ZM25.2499 29.0417H18.7499V26.875H25.2499V29.0417Z"
          fill="#FCDC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1665 30.125H32.8332V32.2917H11.1665V30.125Z"
          fill="#FCDC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_3422">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const ParkingServiceIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_3445)">
        <rect opacity="0.15" width="44" height="44" rx="8.8" fill="#0CD68A" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 16C19.7032 16 19.4218 16.1318 19.2318 16.3598L16.9641 19.081L13.0528 19.7922C11.8641 20.0083 11 21.0436 11 22.2519V25C11 25.5523 11.4477 26 12 26H14C14.5523 26 15 25.5523 15 25V24H17V25C17 25.5523 17.4477 26 18 26H26C26.5523 26 27 25.5523 27 25V24H29V25C29 25.5523 29.4477 26 30 26H32C32.5523 26 33 25.5523 33 25V21.5C33 20.1193 31.8807 19 30.5 19H29.9684L27.7682 16.3598C27.5782 16.1318 27.2968 16 27 16H20Z"
          fill="#0CD68A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 24C15.4477 24 15 24.4477 15 25C15 25.5523 15.4477 26 16 26C16.5523 26 17 25.5523 17 25C17 24.4477 16.5523 24 16 24ZM13 25C13 23.3431 14.3431 22 16 22C17.6569 22 19 23.3431 19 25C19 26.6569 17.6569 28 16 28C14.3431 28 13 26.6569 13 25Z"
          fill="#0CD68A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 24C27.4477 24 27 24.4477 27 25C27 25.5523 27.4477 26 28 26C28.5523 26 29 25.5523 29 25C29 24.4477 28.5523 24 28 24ZM25 25C25 23.3431 26.3431 22 28 22C29.6569 22 31 23.3431 31 25C31 26.6569 29.6569 28 28 28C26.3431 28 25 26.6569 25 25Z"
          fill="#0CD68A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_3445">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const LiftServiceIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_3470)">
        <rect opacity="0.15" width="44" height="44" rx="8.8" fill="#FD346E" />
        <g clipPath="url(#clip1_1537_3470)">
          <path
            d="M20.3331 18.6667H16.9998V32.0001H20.3331V18.6667Z"
            fill="#FD346E"
          />
          <path
            d="M25.3331 18.6667H21.9998V32.0001H25.3331V18.6667Z"
            fill="#FD346E"
          />
          <path
            d="M29.5 12H14.5C13.1217 12 12 13.1217 12 14.5V29.5C12 30.8783 13.1217 32 14.5 32H15.3333V18.6667C15.3333 17.7475 16.0808 17 17 17H21.1667H25.3334C26.2525 17 27 17.7475 27 18.6667V32H29.5C30.8783 32 32 30.8783 32 29.5V14.5C32 13.1217 30.8783 12 29.5 12ZM23.6667 16.1667H18.6667C18.2059 16.1667 17.8334 15.7934 17.8334 15.3334C17.8334 14.8734 18.2058 14.5 18.6667 14.5H23.6667C24.1275 14.5 24.5 14.8733 24.5 15.3333C24.5 15.7933 24.1275 16.1667 23.6667 16.1667ZM28.6667 27.8333C28.2067 27.8333 27.8334 27.46 27.8334 27C27.8334 26.54 28.2067 26.1667 28.6667 26.1667C29.1267 26.1667 29.5 26.54 29.5 27C29.5 27.46 29.1267 27.8333 28.6667 27.8333ZM28.6667 24.5C28.2067 24.5 27.8334 24.1267 27.8334 23.6667C27.8334 23.2067 28.2067 22.8334 28.6667 22.8334C29.1267 22.8334 29.5 23.2067 29.5 23.6667C29.5 24.1267 29.1267 24.5 28.6667 24.5Z"
            fill="#FD346E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1537_3470">
          <rect width="44" height="44" fill="white" />
        </clipPath>
        <clipPath id="clip1_1537_3470">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const SmartHomeIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_3492)">
        <rect opacity="0.15" width="44" height="44" rx="8.8" fill="#0F89FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9167 15.5833C11.9167 14.0645 13.148 12.8333 14.6667 12.8333H29.3334C30.8522 12.8333 32.0834 14.0645 32.0834 15.5833V28.4166H30.2501V15.5833C30.2501 15.077 29.8397 14.6666 29.3334 14.6666H14.6667C14.1605 14.6666 13.7501 15.077 13.7501 15.5833V28.4166H11.9167V15.5833Z"
          fill="#0F89FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6667 25.2083C13.148 25.2083 11.9167 26.4395 11.9167 27.9583V28.8749C11.9167 30.3937 13.148 31.6249 14.6667 31.6249H29.3334C30.8522 31.6249 32.0834 30.3937 32.0834 28.8749V27.9583C32.0834 26.4395 30.8522 25.2083 29.3334 25.2083H14.6667ZM24.2917 29.3333H15.1251V27.4999H24.2917V29.3333ZM26.5834 29.3333H28.4167V27.4999H26.5834V29.3333Z"
          fill="#0F89FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_3492">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const ContactIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6666 11.9875C23.6666 15.5031 22.1116 18.6555 19.6519 20.7944C17.6035 22.5757 14.9276 23.6541 11.9999 23.6541C9.07219 23.6541 6.39635 22.5757 4.34795 20.7944C1.88824 18.6555 0.333252 15.5031 0.333252 11.9875C0.333252 5.54415 5.5566 0.320801 11.9999 0.320801C18.4432 0.320801 23.6666 5.54415 23.6666 11.9875ZM11.9999 20.1541C14.0494 20.1541 15.9224 19.3992 17.3563 18.1523C17.5214 18.0088 17.6806 17.8587 17.8337 17.7026C16.5726 15.6629 14.4304 14.3208 11.9999 14.3208C9.56945 14.3208 7.42729 15.6629 6.16623 17.7026C6.31925 17.8587 6.4785 18.0088 6.64356 18.1523C8.07744 19.3992 9.95054 20.1541 11.9999 20.1541ZM11.9999 11.9875C13.9329 11.9875 15.4999 10.4205 15.4999 8.48747C15.4999 6.55447 13.9329 4.98747 11.9999 4.98747C10.0669 4.98747 8.49992 6.55447 8.49992 8.48747C8.49992 10.4205 10.0669 11.9875 11.9999 11.9875Z"
        fill="white"
      />
    </svg>
  );
};
const RecipeIcon = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0.987305H16C17.1046 0.987305 18 1.88274 18 2.9873V18.1417C18 19.5269 16.6259 20.4926 15.3226 20.0234L13.7608 19.4612C13.2728 19.2855 12.7356 19.3063 12.2626 19.5191L9.82073 20.618C9.29878 20.8529 8.70122 20.8529 8.17927 20.618L5.73737 19.5191C5.26439 19.3063 4.72721 19.2855 4.2392 19.4612L2.67744 20.0234C1.37412 20.4926 0 19.5269 0 18.1417V2.98731C0 1.88274 0.895431 0.987305 2 0.987305ZM5 5.2373C4.58579 5.2373 4.25 5.57309 4.25 5.9873C4.25 6.40152 4.58579 6.7373 5 6.7373H9C9.41421 6.7373 9.75 6.40152 9.75 5.9873C9.75 5.57309 9.41421 5.2373 9 5.2373H5ZM5 9.2373C4.58579 9.2373 4.25 9.57309 4.25 9.9873C4.25 10.4015 4.58579 10.7373 5 10.7373H13C13.4142 10.7373 13.75 10.4015 13.75 9.9873C13.75 9.57309 13.4142 9.2373 13 9.2373H5ZM5 13.2373C4.58579 13.2373 4.25 13.5731 4.25 13.9873C4.25 14.4015 4.58579 14.7373 5 14.7373H13C13.4142 14.7373 13.75 14.4015 13.75 13.9873C13.75 13.5731 13.4142 13.2373 13 13.2373H5Z"
        fill="white"
      />
    </svg>
  );
};
const WalletIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 0.987305H16C17.6787 0.987305 19.1158 2.02139 19.7092 3.4873H6.29074C6.88413 2.02139 8.3213 0.987305 9.99999 0.987305ZM0 10.9873V14.9873H4C5.10457 14.9873 6 14.0919 6 12.9873C6 11.8827 5.10457 10.9873 4 10.9873H0ZM20 4.9873H4C1.79086 4.9873 0 6.77817 0 8.9873V9.4873H4C5.933 9.4873 7.5 11.0543 7.5 12.9873C7.5 14.9203 5.933 16.4873 4 16.4873H0V16.9873C0 19.1964 1.79086 20.9873 4 20.9873H16C18.2091 20.9873 20 19.1964 20 16.9873V4.9873Z"
        fill="white"
      />
    </svg>
  );
};
const UserIcon = () => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79175 0.804199C3.60562 0.804199 1.83341 2.57641 1.83341 4.76253C1.83341 6.94866 3.60562 8.72087 5.79175 8.72087C7.97788 8.72087 9.75008 6.94866 9.75008 4.76253C9.75008 2.57641 7.97788 0.804199 5.79175 0.804199ZM5.79175 2.0542C7.28752 2.0542 8.50008 3.26676 8.50008 4.76253C8.50008 6.2583 7.28752 7.47087 5.79175 7.47087C4.29598 7.47087 3.08341 6.2583 3.08341 4.76253C3.08341 3.26676 4.29598 2.0542 5.79175 2.0542ZM11.4127 13.748C11.318 11.6459 9.58382 9.97082 7.45841 9.97082H4.12508L3.94389 9.9749C1.84187 10.0696 0.166748 11.8038 0.166748 13.9292V15.5958L0.172454 15.6806C0.213839 15.9857 0.475335 16.2208 0.791748 16.2208C1.13693 16.2208 1.41675 15.941 1.41675 15.5958V13.9292L1.42104 13.7755C1.50071 12.3512 2.68089 11.2208 4.12508 11.2208H7.45841L7.6121 11.2251C9.03637 11.3048 10.1667 12.485 10.1667 13.9292V15.5958L10.1725 15.6806C10.2138 15.9857 10.4753 16.2208 10.7917 16.2208C11.1369 16.2208 11.4167 15.941 11.4167 15.5958V13.9292L11.4127 13.748Z"
        fill="#848484"
      />
    </svg>
  );
};

const PhoneIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0.0126953C6.72717 0.0126953 6.93399 0.135634 7.04381 0.329608L7.0803 0.405576L8.74696 4.57224C8.8495 4.82858 8.77096 5.11779 8.56247 5.28813L8.48823 5.3403L6.915 6.28353L6.92396 6.29947C7.6177 7.49036 8.5888 8.4946 9.75259 9.22753L10.02 9.38853L10.9641 7.81614C11.1061 7.57939 11.3839 7.46693 11.6457 7.52986L11.7321 7.5574L15.8988 9.22406C16.1097 9.30843 16.2561 9.49939 16.286 9.72028L16.2917 9.80436V13.1377C16.2917 14.4033 15.2657 15.4294 13.9621 15.4282C6.9198 15.0002 1.30411 9.38456 0.875 2.30436C0.875 1.08559 1.82642 0.0890251 3.02706 0.0168776L3.16667 0.0126953H6.5ZM6.07668 1.2627H3.16668C2.59138 1.2627 2.12501 1.72907 2.12386 2.26645C2.5135 8.67808 7.62629 13.7909 14 14.1794C14.5394 14.1794 14.983 13.7695 15.0363 13.2442L15.0417 13.1377V10.2277L11.7617 8.9152L10.7859 10.5426C10.6338 10.7962 10.328 10.9051 10.0545 10.8147L9.97357 10.7816C8.04058 9.82825 6.47613 8.2638 5.52281 6.33081C5.39202 6.06561 5.46602 5.74951 5.69011 5.56849L5.76179 5.51843L7.38835 4.54186L6.07668 1.2627Z"
        fill="#848484"
      />
    </svg>
  );
};
const ChatIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.97152 2.56603C7.14233 0.316507 11.6789 0.500447 14.61 3.00049C17.58 5.53369 17.9845 9.6631 15.5439 12.624C13.2377 15.4218 9.07225 16.3466 5.60207 14.9057L5.34169 14.7914L1.62862 15.5823L1.54595 15.5939L1.46706 15.5948L1.38464 15.5849L1.30379 15.564L1.22943 15.534L1.15502 15.4914C1.12902 15.4745 1.10743 15.4578 1.08712 15.4399L1.03781 15.3914L1.01163 15.3608L0.980854 15.318L0.943354 15.253C0.91716 15.2024 0.89938 15.1516 0.888292 15.0992L0.876636 15.0165L0.875732 14.9376L0.885687 14.8552L0.907521 14.7697L1.89585 11.8055L1.82414 11.6883C0.0432787 8.6615 0.853455 4.91269 3.78174 2.70484L3.97152 2.56603ZM13.7988 3.95154C11.3048 1.8243 7.40046 1.66599 4.69479 3.58552C2.02916 5.47665 1.358 8.79245 3.1011 11.3705C3.1918 11.5046 3.22621 11.6675 3.1996 11.8248L3.17627 11.9182L2.43835 14.1314L5.28661 13.5259C5.39546 13.5027 5.508 13.5092 5.61273 13.5438L5.68964 13.575C8.73372 15.0528 12.5442 14.2979 14.5793 11.829C16.5824 9.39877 16.254 6.04563 13.7988 3.95154ZM9.61934 8.21896C9.57795 7.9139 9.31645 7.67877 9.00004 7.67877C8.65486 7.67877 8.37504 7.95859 8.37504 8.30377L8.38075 8.39691C8.42213 8.70198 8.68363 8.93711 9.00004 8.93711C9.34522 8.93711 9.62504 8.65728 9.62504 8.3121L9.61934 8.21896ZM5.66667 7.67877C5.98308 7.67877 6.24458 7.9139 6.28596 8.21896L6.29167 8.3121C6.29167 8.65728 6.01185 8.93711 5.66667 8.93711C5.35025 8.93711 5.08876 8.70198 5.04737 8.39691L5.04167 8.30377C5.04167 7.95859 5.32149 7.67877 5.66667 7.67877ZM12.9527 8.21896C12.9113 7.9139 12.6498 7.67877 12.3334 7.67877C11.9882 7.67877 11.7084 7.95859 11.7084 8.30377L11.7141 8.39691C11.7555 8.70198 12.017 8.93711 12.3334 8.93711C12.6786 8.93711 12.9584 8.65728 12.9584 8.3121L12.9527 8.21896Z"
        fill="#848484"
      />
    </svg>
  );
};
const BuildingIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6569 10.5245C13.9527 10.7617 14.1248 11.1204 14.1248 11.4995V26.4995C14.1248 27.1899 13.5651 27.7495 12.8748 27.7495H1.62476C0.9344 27.7495 0.374756 27.1899 0.374756 26.4995V13.9995C0.374756 13.4136 0.781671 12.9064 1.35359 12.7793L12.6036 10.2793C12.9737 10.197 13.3612 10.2872 13.6569 10.5245ZM2.87476 15.0022V25.2495H11.6248V13.0578L2.87476 15.0022Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.125 18.375V16.5H6.625V18.375H4.125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.125 23.375V21.5H6.625V23.375H4.125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.875 1.50001C27.875 1.12936 27.7105 0.777816 27.4259 0.54032C27.1414 0.302825 26.7661 0.203868 26.4014 0.270173L12.6514 2.77017C12.057 2.87824 11.625 3.3959 11.625 4.00001V26.5C11.625 27.1904 12.1846 27.75 12.875 27.75H26.625C27.3154 27.75 27.875 27.1904 27.875 26.5V1.50001ZM16 8.375V6.5H18.5V8.375H16ZM16 12.125V14H18.5V12.125H16ZM16 19.625V17.75H18.5V19.625H16ZM21 6.5V8.375H23.5V6.5H21ZM21 14V12.125H23.5V14H21ZM21 17.75V19.625H23.5V17.75H21Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 18.375V16.5H10.375V18.375H7.875Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 23.375V21.5H10.375V23.375H7.875Z"
        fill="white"
      />
    </svg>
  );
};
const BriefcaseIcon = () => {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.75C7 1.67893 8.67893 0 10.75 0H18.25C20.3211 0 22 1.67893 22 3.75V5C22 5.69036 21.4404 6.25 20.75 6.25H8.25C7.55964 6.25 7 5.69036 7 5V3.75ZM10.75 2.5C10.0596 2.5 9.5 3.05964 9.5 3.75H19.5C19.5 3.05964 18.9404 2.5 18.25 2.5H10.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14.9999V15.6249H9.5V14.9999H19.5V15.6249H22V14.9999H23.1743C25.4686 14.9999 27.4685 13.4384 28.025 11.2126L28.2512 10.3076V21.2499C28.2512 23.321 26.5723 24.9999 24.5012 24.9999H4.50122C2.43015 24.9999 0.751221 23.321 0.751221 21.2499V10.3077L0.977435 11.2126C1.53389 13.4384 3.53381 14.9999 5.82815 14.9999H7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20253 3.75C2.76289 3.75 0.972796 6.04271 1.5645 8.40951L2.1895 10.9095C2.60684 12.5789 4.10678 13.75 5.82753 13.75H7.00122V9.375H9.50122V13.75H19.5012V9.375H22.0012V13.75H23.1737C24.8944 13.75 26.3943 12.5789 26.8117 10.9095L27.4367 8.40951C28.0284 6.04271 26.2383 3.75 23.7987 3.75H5.20253Z"
        fill="white"
      />
    </svg>
  );
};
const BookIcon = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.875 1.97746L10.3233 1.70163C8.41407 0.746995 6.30877 0.25 4.17415 0.25H2.125C1.43464 0.25 0.875 0.809644 0.875 1.5V19C0.875 19.6904 1.43464 20.25 2.125 20.25H4.17415C5.92066 20.25 7.64318 20.6566 9.2053 21.4377L10.875 22.2725V1.97746Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.375 22.2725L15.0447 21.4377C16.6068 20.6566 18.3293 20.25 20.0758 20.25H22.125C22.8154 20.25 23.375 19.6904 23.375 19V1.5C23.375 0.809644 22.8154 0.25 22.125 0.25H20.0758C17.9412 0.25 15.8359 0.746995 13.9267 1.70163L13.375 1.97746V22.2725Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.17415 24H2.125V21.5H4.17415C6.30877 21.5 8.41407 21.997 10.3233 22.9516L12.125 23.8525L13.9267 22.9516C15.8359 21.997 17.9412 21.5 20.0758 21.5H22.125V24H20.0758C18.3293 24 16.6068 24.4066 15.0447 25.1877L12.684 26.368C12.3321 26.544 11.9179 26.544 11.566 26.368L9.2053 25.1877C7.64318 24.4066 5.92066 24 4.17415 24Z"
        fill="white"
      />
    </svg>
  );
};
const OfficeIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5.25C7 4.55964 7.55964 4 8.25 4H20.75C21.4404 4 22 4.55964 22 5.25V26.5C22 27.1904 21.4404 27.75 20.75 27.75H8.25C7.55964 27.75 7 27.1904 7 26.5V5.25ZM9.5 6.5V25.25H19.5V6.5H9.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 12.75C9.5 12.0596 8.94036 11.5 8.25 11.5H2C1.30964 11.5 0.75 12.0596 0.75 12.75V26.5C0.75 27.1904 1.30964 27.75 2 27.75H8.25C8.94036 27.75 9.5 27.1904 9.5 26.5V12.75ZM7 14V25.25H3.25V14H7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.25 16.5C28.25 15.8096 27.6904 15.25 27 15.25H20.75C20.0596 15.25 19.5 15.8096 19.5 16.5V26.5C19.5 27.1904 20.0596 27.75 20.75 27.75H27C27.6904 27.75 28.25 27.1904 28.25 26.5V16.5ZM25.75 17.75V25.25H22V17.75H25.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 6.5C8.80964 6.5 8.25 5.94036 8.25 5.25V2.75C8.25 1.36929 9.36929 0.25 10.75 0.25H18.25C19.6307 0.25 20.75 1.36929 20.75 2.75V5.25C20.75 5.94036 20.1904 6.5 19.5 6.5H9.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.625 14C11.9346 14 11.375 13.4404 11.375 12.75V9C11.375 8.30964 11.9346 7.75 12.625 7.75H16.375C17.0654 7.75 17.625 8.30964 17.625 9V12.75C17.625 13.4404 17.0654 14 16.375 14H12.625Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 27.75C11.3096 27.75 10.75 27.1904 10.75 26.5V19C10.75 18.3096 11.3096 17.75 12 17.75H17C17.6904 17.75 18.25 18.3096 18.25 19V26.5C18.25 27.1904 17.6904 27.75 17 27.75H12Z"
        fill="white"
      />
    </svg>
  );
};
const AutomaticationIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 2.5C0.25 1.11929 1.36929 0 2.75 0H25.25C26.6307 0 27.75 1.11929 27.75 2.5V25C27.75 26.3807 26.6307 27.5 25.25 27.5H2.75C1.36929 27.5 0.25 26.3807 0.25 25V2.5ZM25.25 2.5L2.75 2.5V25H25.25V2.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 2.5C0.25 1.11929 1.36929 0 2.75 0H25.25C26.6307 0 27.75 1.11929 27.75 2.5V7.5C27.75 8.19036 27.1904 8.75 26.5 8.75H1.5C0.809644 8.75 0.25 8.19036 0.25 7.5V2.5ZM25.25 2.5H2.75V6.25H25.25V2.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.125 5.625H4.625V3.125H7.125V5.625Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.125 5.625H9.625V3.125H12.125V5.625Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.125 5.625H14.625V3.125H17.125V5.625Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 8.75C0.25 7.36929 1.36929 6.25 2.75 6.25H25.25C26.6307 6.25 27.75 7.36929 27.75 8.75V25C27.75 26.3807 26.6307 27.5 25.25 27.5H2.75C1.36929 27.5 0.25 26.3807 0.25 25V8.75ZM4 16.25C4 14.5241 5.39911 13.125 7.125 13.125C8.85089 13.125 10.25 14.5241 10.25 16.25C10.25 16.4065 10.2385 16.5603 10.2163 16.7106L12.4021 17.8035C12.9687 17.2302 13.7553 16.875 14.625 16.875C15.1662 16.875 15.6752 17.0126 16.119 17.2546L18.2056 14.7507C17.9166 14.2771 17.75 13.7205 17.75 13.125C17.75 11.3991 19.1491 10 20.875 10C22.6009 10 24 11.3991 24 13.125C24 14.8509 22.6009 16.25 20.875 16.25C20.6648 16.25 20.4594 16.2292 20.2608 16.1897L17.6708 19.2977C17.7226 19.5234 17.75 19.7585 17.75 20C17.75 21.7259 16.3509 23.125 14.625 23.125C12.9492 23.125 11.5814 21.8059 11.5035 20.1493L8.87871 18.8369C8.37869 19.1765 7.77502 19.375 7.125 19.375C5.39911 19.375 4 17.9759 4 16.25Z"
        fill="white"
      />
    </svg>
  );
};
const MoneyCaseIcon = () => {
  return (
    <svg
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 6.75C0.75 7.44036 1.30964 8 2 8H24.5C25.1904 8 25.75 7.44036 25.75 6.75V4.25C25.75 2.17893 24.0711 0.5 22 0.5H4.5C2.42893 0.5 0.75 2.17893 0.75 4.25V6.75ZM3.25 5.5V4.25C3.25 3.55964 3.80964 3 4.5 3H22C22.6904 3 23.25 3.55964 23.25 4.25V5.5H3.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 6.75C0.75 6.05964 1.30964 5.5 2 5.5H24.5C26.5711 5.5 28.25 7.17893 28.25 9.25V10.5H20.75C17.9886 10.5 15.75 12.7386 15.75 15.5C15.75 18.2614 17.9886 20.5 20.75 20.5H28.25V21.75C28.25 23.8211 26.5711 25.5 24.5 25.5H4.5C2.42893 25.5 0.75 23.8211 0.75 21.75V6.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 16.75H20.75V14.25H22V16.75Z"
        fill="white"
      />
    </svg>
  );
};
const HamburgerIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66666 10.667C5.93028 10.667 5.33333 11.2639 5.33333 12.0003C5.33333 12.7367 5.93028 13.3337 6.66666 13.3337H25.3333C26.0697 13.3337 26.6667 12.7367 26.6667 12.0003C26.6667 11.2639 26.0697 10.667 25.3333 10.667H6.66666Z"
        fill="#777E91"
      />
      <path
        d="M6.66666 18.667C5.93028 18.667 5.33333 19.2639 5.33333 20.0003C5.33333 20.7367 5.93028 21.3337 6.66666 21.3337H25.3333C26.0697 21.3337 26.6667 20.7367 26.6667 20.0003C26.6667 19.2639 26.0697 18.667 25.3333 18.667H6.66666Z"
        fill="#777E91"
      />
    </svg>
  );
};
const ProductsIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 2.125C0.875 1.43464 1.43464 0.875 2.125 0.875H12.75C13.4404 0.875 14 1.43464 14 2.125V12.125C14 12.8154 13.4404 13.375 12.75 13.375H2.125C1.43464 13.375 0.875 12.8154 0.875 12.125V2.125ZM3.375 3.375V10.875H11.5V3.375H3.375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 15.875C0.875 15.1846 1.43464 14.625 2.125 14.625H12.75C13.4404 14.625 14 15.1846 14 15.875V25.875C14 26.5654 13.4404 27.125 12.75 27.125H2.125C1.43464 27.125 0.875 26.5654 0.875 25.875V15.875ZM3.375 17.125V24.625H11.5V17.125H3.375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 2.125C15.25 1.43464 15.8096 0.875 16.5 0.875H27.125C27.8154 0.875 28.375 1.43464 28.375 2.125V12.125C28.375 12.8154 27.8154 13.375 27.125 13.375H16.5C15.8096 13.375 15.25 12.8154 15.25 12.125V2.125ZM17.75 3.375V10.875H25.875V3.375H17.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 15.875C15.25 15.1846 15.8096 14.625 16.5 14.625H27.125C27.8154 14.625 28.375 15.1846 28.375 15.875V25.875C28.375 26.5654 27.8154 27.125 27.125 27.125H16.5C15.8096 27.125 15.25 26.5654 15.25 25.875V15.875ZM17.75 17.125V24.625H25.875V17.125H17.75Z"
        fill="white"
      />
    </svg>
  );
};
const UserIconWhite = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4999 2.5C11.3933 2.5 8.87494 5.0184 8.87494 8.125C8.87494 11.2316 11.3933 13.75 14.4999 13.75C17.6065 13.75 20.1249 11.2316 20.1249 8.125C20.1249 5.0184 17.6065 2.5 14.4999 2.5ZM6.37494 8.125C6.37494 3.63769 10.0126 0 14.4999 0C18.9873 0 22.6249 3.63769 22.6249 8.125C22.6249 12.6123 18.9873 16.25 14.4999 16.25C10.0126 16.25 6.37494 12.6123 6.37494 8.125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4999 16.25C9.04369 16.25 4.61407 19.678 3.51641 24.0768C3.46087 24.2994 3.51647 24.4932 3.67561 24.665C3.84982 24.853 4.14645 25 4.49992 25H24.4999C24.8534 25 25.15 24.853 25.3242 24.665C25.4834 24.4932 25.539 24.2994 25.4834 24.0768C24.3858 19.678 19.9561 16.25 14.4999 16.25ZM1.09079 23.4715C2.49952 17.8261 8.0393 13.75 14.4999 13.75C20.9605 13.75 26.5003 17.8261 27.909 23.4715C28.4934 25.8134 26.4587 27.5 24.4999 27.5H4.49992C2.54109 27.5 0.506421 25.8134 1.09079 23.4715Z"
        fill="white"
      />
    </svg>
  );
};
const UsersIcon = () => {
  return (
    <svg
      width="29"
      height="22"
      viewBox="0 0 29 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2849 3.16667C8.29239 3.16667 6.6771 4.78195 6.6771 6.77451C6.6771 8.76707 8.29239 10.3824 10.2849 10.3824V13.049C6.81963 13.049 4.01044 10.2398 4.01044 6.77451C4.01044 3.30919 6.81963 0.5 10.2849 0.5V3.16667Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2843 13.0495C6.16927 13.0495 2.83335 16.3854 2.83335 20.5005H0.166687C0.166687 14.9126 4.69651 10.3828 10.2843 10.3828V13.0495Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1667 3.16667C16.0036 3.16667 14.25 4.92022 14.25 7.08333C14.25 9.24645 16.0036 11 18.1667 11C20.3298 11 22.0834 9.24645 22.0834 7.08333C22.0834 4.92022 20.3298 3.16667 18.1667 3.16667ZM11.5834 7.08333C11.5834 3.44746 14.5308 0.5 18.1667 0.5C21.8026 0.5 24.75 3.44746 24.75 7.08333C24.75 10.7192 21.8026 13.6667 18.1667 13.6667C14.5308 13.6667 11.5834 10.7192 11.5834 7.08333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1667 13.6667C13.7484 13.6667 10.1667 17.2484 10.1667 21.6667H7.50003C7.50003 15.7756 12.2757 11 18.1667 11C24.0577 11 28.8334 15.7756 28.8334 21.6667H26.1667C26.1667 17.2484 22.585 13.6667 18.1667 13.6667Z"
        fill="white"
      />
    </svg>
  );
};
export {
  SearchEye,
  DropDown,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  RightArrow,
  PlayButton,
  EducationIcon,
  ApartamentComplexIcon,
  BusinessCenterIcon,
  ObjectIcon,
  ParkingServiceIcon,
  LiftServiceIcon,
  SmartHomeIcon,
  ContactIcon,
  RecipeIcon,
  WalletIcon,
  UserIcon,
  PhoneIcon,
  ChatIcon,
  BuildingIcon,
  BriefcaseIcon,
  BookIcon,
  OfficeIcon,
  AutomaticationIcon,
  MoneyCaseIcon,
  HamburgerIcon,
  ProductsIcon,
  UserIconWhite,
  UsersIcon,
};
