import { useState } from "react";
// import { NavLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import Logo from "../../../assets/images/pro_bill_logo.svg";
import { SearchEye, DropDown, HamburgerIcon } from "../../../icons";
import RegisterPopUp from "../../UI/registerPopup";
import Wrapper from "../../UI/wrapper";
import styles from "./index.module.css";
function TheHeader() {
  const [register, setRegister] = useState(false);
  return (
    <Wrapper>
      {register ? <RegisterPopUp setRegister={setRegister} /> : null}

      <nav className={styles["bar"]}>
        <Link to="/">
          <img className={styles["logo"]} src={Logo} alt="Probill" />
        </Link>
        <ul className={styles["list"]}>
          <li>
            <Link to="tutorial" smooth={true}>
              Probill nədir?
            </Link>
          </li>
          <li>
            <Link to="customers" smooth={true}>
              Müştərilərimiz
            </Link>
          </li>
          <li>
            <Link to="products" smooth={true}>
              Məhsullar
            </Link>
          </li>

          {/* <li>
            <div className={styles["dropdown1"]}>
              Məhsullar{" "}
              <div className={styles["arrow"]}>
                <DropDown />
              </div>
              <ul className={styles["dropdown-menu1"]}>
                <li>
                  <Link to="products" smooth={true}>
                    Probill yaşayış kompleksi
                  </Link>
                </li>
                <li>
                  <Link to="products" smooth={true}>
                    Təhsil billinqi
                  </Link>
                </li>
                <li>
                  <Link to="products" smooth={true}>
                    Plaza idarəetmə
                  </Link>
                </li>
                {/* <li>
                  <Link to="products" smooth={true}>
                    Probill qeyri-yaşayış...
                  </Link>
                </li> */}
          {/* <li>
                  <Link to="products" smooth={true}>
                    Parkinq servis billinq...
                  </Link>
                </li>
                <li>
                  <Link to="products" smooth={true}>
                    Lift servis billinq
                  </Link>
                </li>
                <li>
                  <Link to="products" smooth={true}>
                    Damafon servis billing...
                  </Link>
                </li>
              </ul>
            </div>
          </li>  */}
          <li>
            <Link to="forWho" smooth={true}>
              Kimlər üçündür?
            </Link>
          </li>

          <li>
            <Link to="solutions" smooth={true}>
              Həllər
            </Link>
          </li>
          {/* <li>
            <Link>Qiymət</Link>
          </li> */}
          <li>
            <Link to="contact" smooth={true}>
              Əlaqə
            </Link>
          </li>
        </ul>
        <div className={styles["buttons"]}>
          <div className={styles["search"]}>
            <SearchEye />
          </div>
          <div className={styles["right"]}>
            {/* <button className={styles["login"]}>
              Daxil ol
            </button> */}
            <button
              className={styles["demo"]}
              onClick={() => setRegister(true)}
            >
              Demo
            </button>
            <div className={styles["dropdown"]}>
              AZE
              <ul className={styles["dropdown-menu"]}>
                <li>AZE</li>
                <li>ENG</li>
                <li>RUS</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles["hamburger"]}>
          <HamburgerIcon />
        </div>
      </nav>
    </Wrapper>
  );
}
export default TheHeader;
