import Card from "../UI/card";
import SubTitle from "../UI/subTitle";
import Title from "../UI/title";
import Wrapper from "../UI/wrapper";
import { ContactIcon, RecipeIcon, WalletIcon, UsersIcon } from "../../icons";
import styles from "./index.module.css";
import RedBlur from "../../assets/images/decorations/red-blur.svg";
import Rings from "../../assets/images/decorations/rings.svg";
import ShowMore from "../UI/showMore";
const ResidentSolutions = () => {
  return (
    <Wrapper>
      <div className={styles["blue-blur"]} id="solutions">
        <img src={RedBlur} alt="" className={styles["blue-blur-img"]} />
      </div>
      <div className={styles["rings"]}>
        <img src={Rings} alt="" className={styles["rings-img"]} />
      </div>
      <div className={styles["icon-container"]}>
        <div className={styles["icon"]}>
          <UsersIcon />
        </div>
      </div>
      <Title>Sakin/İcarəçi üçün həllər</Title>
      <SubTitle>
        ProBill, çağdaş dövrün tələblərinə uyğun olaraq ödəyicilərə müstəsna
        həllər təklif edir
      </SubTitle>
      <ShowMore iden={"resSolution"}>
        <div className={styles["card-grid"]}>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-green"]}>
                <ContactIcon />
              </span>
              <h3>Zamana qənaət</h3>
              <p>
                Qurumlara yaxınlaşaraq vaxt itirmədən olduğunuz yerdən ödəniş
                etmək imkanı
              </p>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-red"]}>
                <RecipeIcon />
              </span>
              <h3>Borc və ödənişlərə baxma imkanı</h3>
              <p>
                Onlayn ödəmə sistemində müqavilə kodunuzu daxil edərək cari
                balansa baxmaq imkanı
              </p>
            </div>
          </Card>
          <Card>
            <div className={styles["card-container"]}>
              <span className={styles["icon-back-yellow"]}>
                <WalletIcon />
              </span>
              <h3>Onlayn və oflayn ödəniş etmə imkanı</h3>
              <p>
                Yaxınlıqdaki müvafiq terminallardan və ya mobil tətbiqdən ödəniş
                etmək imkanı
              </p>
            </div>
          </Card>
        </div>
      </ShowMore>
    </Wrapper>
  );
};
export default ResidentSolutions;
