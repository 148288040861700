import SubTitle from "../UI/subTitle";
import Title from "../UI/title";
import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import Marquee from "react-fast-marquee";
// import Kassam from "../../assets/images/partners-logo/kassam.svg";
import Hikvision from "../../assets/images/partners-logo/hikvision-logo.svg";
import Dahua from "../../assets/images/partners-logo/dahua.svg";
// import Hytera from "../../assets/images/partners-logo/hytera_logo.svg";
// import Fpay from "../../assets/images/partners-logo/fpay-logo.svg";
import SmartLife from "../../assets/images/partners-logo/smart-life.svg";
//new Logos
import kassam from "../../assets/images/partners-logo/kassam.svg";
import millikart from "../../assets/images/partners-logo/millikart.svg";
import itech from "../../assets/images/partners-logo/itech.svg";
import planradar from "../../assets/images/partners-logo/planradar.svg";
import whelp from "../../assets/images/partners-logo/whelp.svg";

import Waves from "../../assets/images/decorations/waves1.svg";
const Partners = () => {
  return (
    <Wrapper>
      <div className={styles["container"]}>
        <div className={styles["waves"]}>
          <img src={Waves} alt="" className={styles["waves-img"]} />
        </div>
        <div style={{ marginTop: 100 + "px" }}></div>
        <Title>Partnyorlar</Title>
        <SubTitle>
          Proqram istifaçilərinə dəyər yaratmaq məqsədilə müvafiq qurumlarla
          qurulmuş partnyorluq əlaqələrimiz
        </SubTitle>
        <div className={styles["partners"]}>
          <Marquee
            gradient={false}
            pauseOnHover={true}
            speed={40}
            className={styles["marquee"]}
          >
            <div className={styles["partner-logo"]}>
              <img src={kassam} alt="kassam logo" />
            </div>
            <div className={styles["partner-logo"]}>
              <img src={millikart} alt="millikart logo" />
            </div>
            <div className={styles["partner-logo"]}>
              <img src={itech} alt="itech logo" />
            </div>
            <div className={styles["partner-logo"]}>
              <img src={planradar} alt="planradar logo" />
            </div>
            <div className={styles["partner-logo"]}>
              <img src={whelp} alt="whelp logo" />
            </div>
            <div className={styles["partner-logo"]}>
              <img src={Dahua} alt="dahua logo" />
            </div>
            <div className={styles["partner-logo"]}>
              <img src={Hikvision} alt="hikvision logo" />
            </div>
            {/* <div className={styles["partner-logo"]}>
              <img src={Hytera} alt="hytera logo" />
            </div> */}
            {/* <div className={styles["partner-logo"]}>
              <img src={Fpay} alt="fpay logo" />
            </div> */}
            <div className={styles["partner-logo"]}>
              <img src={SmartLife} alt="smart life logo" />
            </div>
          </Marquee>
        </div>
      </div>
    </Wrapper>
  );
};
export default Partners;
