import { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import Popup from "../UI/popup";

const OnlineApplication = () => {
  const [name, setName] = useState("");
  const [corpName, setCorpName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [state, handleSubmit] = useForm("xayzjoer");

  const nameHandler = (e) => {
    setName(e.target.value);
  };
  const corpNameHandler = (e) => {
    setCorpName(e.target.value);
  };
  const numberHandler = (e) => {
    setNumber(e.target.value);
  };
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const messageHandler = (e) => {
    setMessage(e.target.value);
  };
  const disableHandler = () => {
    if (
      name === "" ||
      corpName === "" ||
      number === "" ||
      email === "" ||
      message === ""
    ) {
      return true;
    } else return false;
  };
  const handleClear = () => {
    setName("");
    setCorpName("");
    setNumber("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className={styles["back"]}>
      {popup ? <Popup setPopup={setPopup} /> : null}
      <Wrapper>
        <div className={styles["inner-content"]}>
          <div>
            <h3>Onlayn Müraciət</h3>
            <p>
              Sayt üzərindən etdiyiniz müraciətlər 2 iş günü ərzində geri dönüş
              alacaqdır.
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <label className={styles["user"]}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Ad, Soyad"
                  value={name}
                  onChange={nameHandler}
                />
              </label>
              <label className={styles["phone"]}>
                <input
                  type="text"
                  id="corpName"
                  name="corpName"
                  placeholder="Müəssənin Adı"
                  value={corpName}
                  onChange={corpNameHandler}
                />
              </label>
              <label className={styles["phone"]}>
                <input
                  type="text"
                  id="num"
                  name="num"
                  placeholder="Nömrə"
                  value={number}
                  onChange={numberHandler}
                />
              </label>
              <label className={styles["phone"]}>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={emailHandler}
                />
              </label>
              <label className={styles["message"]}>
                <input
                  type="text"
                  id="message"
                  name="message"
                  placeholder="Mətn"
                  value={message}
                  onChange={messageHandler}
                />
              </label>
              <button
                type="submit"
                disabled={disableHandler()}
                className={disableHandler() ? null : styles["active-button"]}
                onClick={() => {
                  setPopup(true);
                }}
              >
                Göndər
              </button>
            </form>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
export default OnlineApplication;
