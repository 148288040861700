import Logo1 from '../../assets/images/customers-logo/ag-saray-residence.svg';
import Logo2 from '../../assets/images/customers-logo/baku-city-residence.svg';
import Logo3 from '../../assets/images/customers-logo/bayil-bay.svg';
import Logo4 from '../../assets/images/customers-logo/chinar-park.svg';
import Logo5 from '../../assets/images/customers-logo/huner-group.svg';
import Logo6 from '../../assets/images/customers-logo/ideal-yasham.svg';
import Logo7 from '../../assets/images/customers-logo/inci-residence.svg';
import Logo8 from '../../assets/images/customers-logo/jurnalist-evi.svg';
import Logo9 from '../../assets/images/customers-logo/nerimanov-14.svg';
import Logo10 from '../../assets/images/customers-logo/rahatligin-mekani-gold.svg';
import Logo11 from '../../assets/images/customers-logo/sabah-residence.svg';
import Logo12 from  '../../assets/images/customers-logo/abada.svg';
import Logo13 from  '../../assets/images/customers-logo/goldenrose.svg';
import Logo14 from  '../../assets/images/customers-logo/harmony.svg';
import Logo15 from  '../../assets/images/customers-logo/kassam.svg';
import Logo16 from '../../assets/images/customers-logo/hytera_logo.svg'
import Logo17 from  '../../assets/images/customers-logo/xetai-32.svg';
import Logo18 from '../../assets/images/customers-logo/alyans-international.svg'
import Logo19 from '../../assets/images/customers-logo/azenne.svg'
import Logo20 from '../../assets/images/customers-logo/goygol-mktb.svg'
import Logo21 from '../../assets/images/customers-logo/white-stone.svg'
import Logo22 from '../../assets/images/customers-logo/temiz-seher.svg'
import Logo23 from '../../assets/images/customers-logo/itech.svg'
import Logo24 from '../../assets/images/customers-logo/mn.svg'

const logos = [
  Logo24,
  Logo23,
  Logo22,
  Logo21,
  Logo20,
  Logo19,
  Logo18,
  Logo17,
  Logo16,
  Logo5,
  Logo7,
  Logo3,
  Logo2,
  Logo6,
  Logo1,
  Logo13,
  Logo4,
  Logo8,
  Logo9,
  Logo10,
  Logo11,
  Logo12,
  Logo14,
  Logo15,
];
export { logos }