import Wrapper from "../UI/wrapper";
import Img from "../../assets/images/panel.webp";
import styles from "./index.module.css";
import { PlayButton } from "../../icons";
import { Link } from "react-router-dom";
import IframePopup from "../UI/iframePopup";
import { useState } from "react";
import Rings from "../../assets/images/decorations/rings.svg";
import BlueBlur from "../../assets/images/decorations/blue-blur.svg";
import Plusses from "../../assets/images/decorations/plusses.svg";
const Tutorial = () => {
  const [playVideo, setPlayVideo] = useState(false);
  return (
    <div id="tutorial">
      {playVideo ? (
        <IframePopup videoCode={""} setPlayVideo={setPlayVideo} />
      ) : null}
      <Wrapper>
        <div className={styles["blue-blur"]}>
          <img src={BlueBlur} alt="" className={styles["blue-blur-img"]} />
        </div>
        <div className={styles["container"]}>
          {!playVideo ? (
            <div
              className={styles["play-btn"]}
              onClick={() => setPlayVideo(true)}
            >
              <PlayButton />
            </div>
          ) : null}

          <div className={styles["texts"]}>
            <div className={styles["red-line"]}></div>
            <h2>
              ProBill nədir <br /> və necə işləyir?
            </h2>
            <div className={styles["flex"]}>
              <p>
                ProBill, fərqli sferalara xidmət göstərən billinq sistemidir.
                Sistemdə real vaxt rejimində formalaşan hesabatlar, monitorinq
                və marketinq alətləri, tariflər, elektron müqavilə və qəbzlər
                avtomatik şəkildə formalaşır.
              </p>
              <div className={styles["red-line-vertical"]}></div>
            </div>
            <Link>Daha ətraflı &rarr;</Link>
          </div>
          <div className={styles["imgs"]}>
            <img src={Img} alt="" className={styles["panel"]} />
            <div
              className={styles["play-btn-m"]}
              onClick={() => setPlayVideo(true)}
            >
              <PlayButton />
            </div>
            <div className={styles["back"]}>
              <img src={Rings} alt="" />
            </div>
            <div className={styles["back1"]}>
              <img src={Plusses} alt="" />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
export default Tutorial;
