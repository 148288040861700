import styles from "./index.module.css";
import { Link } from "react-router-dom";
const RegisterPopup = ({ setRegister }) => {
  return (
    <div className={styles["container"]}>
      <div
        className={styles["overlay"]}
        onClick={() => setRegister(false)}
      ></div>

      <div className={styles["popup"]}>
        <h4>Qeydiyyat</h4>
        <form>
          <label>Ad soyad</label>
          <input type="text" placeholder="Elçin Rəhimov" />
          <label>Ata adı</label>
          <input type="text" placeholder="Musa" />
          <label>Mənzil/Obyekt sayı</label>
          <input type="text" placeholder="1234" />
          <label>Mail ünvanı</label>
          <input type="text" placeholder="email@probill.az" />
          <label>Mobil nömrə</label>
          <input type="text" placeholder="055 555 55 55" />
          <label>Şirkət adı</label>
          <input type="text" placeholder="Musa holdinq" />

          <div className={styles["terms"]}>
            <input type="checkbox" />
            <div>
              <Link>Sözləşməni</Link> qəbul edirəm.
            </div>
          </div>
          <button className={styles["login-btn"]}>Qeydiyyat</button>
        </form>
      </div>
    </div>
  );
};
export default RegisterPopup;
