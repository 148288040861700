import styles from "./index.module.css";
const Popup = ({ setPopup }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["overlay"]} onClick={() => setPopup(false)}></div>

      <div className={styles["popup"]}>
        <h4>Müraciətiniz qəbul olundu!</h4>
        <p> 2 iş günü ərzində geri dönüş alacaqsız.</p>
        <div className={styles["button"]}>
          <button className={styles["call-up"]} onClick={() => setPopup(false)}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
export default Popup;
