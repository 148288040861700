import Wrapper from "../UI/wrapper";
import styles from "./index.module.css";
import BackgroundImage from "../../assets/images/centaurus-building-architectural-engineering-architecture-apartment-building-64de2744f0bd8a8232f8982.webp";
import RegisterPopUp from "../UI/registerPopup";

import Divider from "../../assets/images/decorations/Divider.svg";
import Divider1 from "../../assets/images/decorations/Divider-1.svg";
import Divider2 from "../../assets/images/decorations/Divider-2.svg";
import Divider3 from "../../assets/images/decorations/Divider-3.svg";
import Dots from "../../assets/images/decorations/dots.svg";
import { useState } from "react";

const Jumbotron = () => {
  const [register, setRegister] = useState(false);
  const DividerTop = () => {
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      return window.innerWidth - 1200 - (window.innerWidth - 1200) / 1.8;
    } else if (window.innerWidth < 768) {
      return window.innerWidth - 768 - (window.innerWidth - 768) / 1.8;
    }
    return 0;
  };
  const Divider1Top = () => {
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      return window.innerWidth - 1200 - (window.innerWidth - 1400) / 28.8;
    } else if (window.innerWidth < 768) {
      return window.innerWidth - 768 - (window.innerWidth - 968) / 28.8;
    }
    return -50;
  };
  const Divider2Top = () => {
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      return window.innerWidth - 1200 - (window.innerWidth - 1200) / 3.8;
    } else if (window.innerWidth < 768) {
      return window.innerWidth - 768 - (window.innerWidth - 768) / 3.8;
    }
    return 0;
  };
  const DotsTop = () => {
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      return 577 - (1200 - window.innerWidth) * 0.7;
    } else if (window.innerWidth < 768) {
      return 577 - (1200 - window.innerWidth) * 0.7;
    }
    return 577;
  };

  return (
    <>
      {register ? <RegisterPopUp setRegister={setRegister} /> : null}
      <Wrapper>
        <div className={styles["decor"]}>
          <img src={Dots} alt="" className={styles["dots-left"]} />
        </div>
        <div className={styles["decor1"]}>
          <img
            src={Dots}
            alt=""
            className={styles["dots-right"]}
            style={{ top: DotsTop() }}
          />
        </div>
      </Wrapper>
      <div className={styles[("back", "jumbo-back")]}>
        <img className={styles["back-img"]} src={BackgroundImage} alt="" />
      </div>
      <Wrapper>
        <div className={styles["home-section"]}>
          <div className={styles["back"]}>
            <img className={styles["back-img"]} src={BackgroundImage} alt="" />
            <img
              className={styles["divider"]}
              style={{ top: DividerTop() + "px" }}
              src={Divider}
              alt=""
            />
            <img
              className={styles["divider1"]}
              src={Divider1}
              alt=""
              style={{ top: Divider1Top() + "px" }}
            />
            <img
              className={styles["divider2"]}
              src={Divider2}
              alt=""
              style={{ top: Divider2Top() + "px" }}
            />
            <img className={styles["divider3"]} src={Divider3} alt="" />
          </div>
        </div>
        <div className={styles["jumbo"]}>
          <div className={styles["red-line"]}></div>
          <h1>
            <span>Yaşayış </span> komplekslərinin idarə etmə sistemi
          </h1>
          <p>
            Probill, çox mənzilli yaşayış və qeyri yaşayış komplekslərinin
            idarəçilərinə dijital asistent, kompleks sakinlərinə isə
            ödənişlərində şəffaflıq təklif edən bir idarə etmə sistemidir.
          </p>
          {/* <div className={styles["stats"]}>
          <div>
          <h3>
          430<span>+</span>
          </h3>
          <p>Müştərilər</p>
          </div>
          <div>
          <h3>
          1500<span>+</span>
          </h3>
          <p>Layihələr</p>
          </div>
          <div>
          <h3>
          2500<span>+</span>
          </h3>
          <p>Müştərilər</p>
          </div>
        </div> */}
          <div className={styles["buttons"]}>
            <button className={styles["call-up"]}>Mənə zəng et</button>
            <button
              className={styles["demo"]}
              onClick={() => setRegister(true)}
            >
              Demo versiya
            </button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default Jumbotron;
